<div style="display: flex;flex-direction:column;margin-top: 5em;">






    <div style='display:flex;flex-direction: column;text-align: center;'>



        <div>
            <img src="assets/me.png" alt="Avatar" style="width:233px;border-radius: 50%;display: block;margin-left: auto;margin-right: auto; max-width: 25%;">

            <h1 style="margin-top: 1em;">Dr. Eros Montin, PhD</h1>
            <a href="https://erosmontin.s3.us-east-1.amazonaws.com/CV.pdf" download="Eros_Montin_CV.pdf" class="cv-button" target="_blank">
                Download my latest CV
            </a>

            <div style="display:flex;flex-direction: col;flex-wrap: wrap;justify-content: center;">
                <h3>
                    <a href="https://mail.google.com/mail/?view=cm&fs=1&to=eros.montin@gmail.com&su=Hey Eros!&body=Let's make great science projects together!" target="_blank">
                        <fa-icon [icon]="at" class="btnInfo"></fa-icon>
                    </a>
                </h3>
                <h3>
                    <a href="http://cai2r.net/people/eros-montin" target="_blank">
                        <fa-icon [icon]="ad" class="btnInfo"></fa-icon>
                    </a>

                </h3>
                <h3>
                    <a href="https://www.linkedin.com/in/eros-montin-46645aab" target="_blank">
                        <fa-icon [icon]="lin" class="btnInfo"></fa-icon>
                    </a>
                </h3>
                <h3>
                    <a href="https://github.com/erosmontin" target="_blank">
                        <fa-icon [icon]="gi" class="btnInfo"></fa-icon>
                    </a>
                </h3>
                <h3>
                    <a href="https://www.instagram.com/erosnitnom" target="_blank">
                        <fa-icon [icon]="in" class="btnInfo"></fa-icon>
                    </a>
                </h3>
            </div>
        </div>
        <app-people-main></app-people-main>
        <app-publication-entry></app-publication-entry>

    </div>

</div>